import { routes } from "@/router/constants";

interface NavLinkItem {
  title: string;
  icon?: React.FC;
  path?: string;
  redirect?: string;
}

export interface NavLink extends NavLinkItem {
  subMenu?: NavLinkItem[];
}
export type VerticalNavItemsType = NavLink[];

const navigation = (): VerticalNavItemsType => [
  {
    title: "Home",
    path: "/",
  },
  {
    title: "Explore",
    path: "/explore",
  },
  {
    title: "Swap",
    redirect: "https://swap.omarket.live",
  },
  {
    title: "Vesting",
    path: "/vesting",
  },
  // {
  //   title: "Reward",
  //   path: "/reward",
  // },
  {
    title: "Stats",
    subMenu: [
      {
        title: "Ranking",
        path: "/ranking",
      },
      {
        title: "Activities",
        path: "/activities",
      },
    ],
  },
  {
    title: "Create",
    subMenu: [
      {
        title: "NFT",
        path: routes.CREATE_NFT,
      },
      {
        title: "Collection",
        path: routes.CREATE_COLLECTION,
      },
    ],
  },
  {
    title: "Lottery",
    path: routes.LOTTERY,
  },
  {
    title: "Launchpad",
    path: routes.LAUNCHPAD,
  },
];

export default navigation;
